window.addEventListener('load', () => {
  window.addEventListener('click', (e) => {
    let elem = e.target.closest('span.close');
    if (!elem) {
      return
    }
    elem.parentNode.classList.add('dismissed');
    elem.parentNode.style.transition = '0.8s';
    elem.parentNode.style.opacity = 0;
  });

  //notice auto hide
  let hide_notice = function () {
    let el = document.querySelector('.admin-notice');
    if (!el) {
      return
    }
    el.classList.add('dismissed');
  };
  window.setTimeout(hide_notice, 10000);
})

