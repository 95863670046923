window.addEventListener('load', () => {

  const onChange = (e) => {
      let list = document.getElementById('override-currency-list');
      let btn = e.target
      if (btn.checked) {
        list.classList.remove('hidden-animated')
      } else {
        list.classList.add('hidden-animated')
      }
  }

  const addListeners = () => {
    btn = document.querySelector('#override-currency-btn input[type="checkbox"]')
    if (!btn) { return }
    btn.addEventListener('change', onChange)
  }

  const removeListeners = () => {
    btn = document.getElementById('override-currency-btn')
    if (!btn) { return }
    btn.removeEventListener('change', onChange)
  }

  document.addEventListener('turbolinks:load', addListeners);
  addListeners();

  document.addEventListener('turbolinks:before-cache', removeListeners);
  removeListeners();
})
